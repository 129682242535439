export const ENDPOINTS = {
  grid: (query = "") => `/v1/api/consultant/grid?${query}`,
  getSkills: () => `/api/services/list`,
  getLanguages: `/api/o/languages`,
  getTags: `/api/consultant/tags`,
  approve: (id) => `/api/profile/approve/${id}`,
  bulkUpdate: () => `/api/consultant/bulk/update`,
  bulkUpload: (templateDownload) => `/v1/api/consultant/bulk/upload?downloadTemplate=${templateDownload}`,
  get: (id) => `/api/profile/consultant/details/${id}`,
  getEarnings: (id) => `/api/consultant/earnings/${id}`,
  getOrders: (id) => `/api/consultant/orders/${id}`,
  getTimeSpent: (id) => `/api/consultant/timespent/${id}`,
  getinvoice: (page, rowPerPage, id, filter) => {
    let url = `/api/profile/get/consultant/paymentRecords/${id}?rowPerPage=` + rowPerPage + "&page=" + page
    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    if (filter?.forDownload) {
      url += `&forDownload=${filter?.forDownload}`
    }
    return url
  },
  save: `/api/profile/save/consultant`,
  updatePassword: `/api/profile/change/password/byAdmin`,
  updateDisableField: (id) => `/api/profile/consultant/details/disable/${id}`,
  disableALlSelected: `/api/profile/consultant/details/disableall`,
  edit: (id) => `/api/profile/update/consultant/${id}`,
  image: (image) => `/api/auth/${image}`,
  payout: `/api/v1/payments/payout/save`,
  isPayout: `/api/v1/payments/payout/present`,
  summary: (id, filter = null) => {
    let url = `/v1/api/consultant/activity-summary?consultant_id=${id}`
    if (filter) {
      url += filter;
    }
    return url;

  },
  summaryAll: (query = null) => `/v1/api/consultant/activity-summary-all${query}`,
  delete: (id) => `/v1/api/consultant/${id}/delete`,
  bulDelete: "/v1/api/consultant/bulk-delete",
};


export const SELECTSTRUCTURE = {
  // shiftName: [
  //   { label: "10-4", value: 1 },
  //   { label: "Male", value: 2 },
  //   { label: "Others", value: 3 },

  // ],
  gender: [
    { label: "Mrs", value: 0 },
    { label: "Mr", value: 1 },
    { label: "Miss", value: 2 },
    { label: "Others", value: 3 },
  ],
};
export const STRUCTURE = [
  {
    label: "Client",
    description: "description...",
    filterLabel: "Client",
    filterName: "corp_id",
    type: "clientSelect",
  },
  {
    label: "Business Unit",
    description: "description...",
    filterLabel: "Business Unit",
    filterName: "bu_id",
    type: "entitySelect",
    dependency: {
      client: 'corp_id'
    }
  },
  {
    label: "Work Shift",
    description: "description...",
    filterLabel: "Work Shift",
    filterName: "dutyhrs_id",
    type: "workshiftSelect",
  },
  {
    label: "Campus",
    description: "description...",
    filterLabel: "Campus",
    filterName: "campus_id",
    type: "campusSelect",
  },
  {
    label: "Rank",
    description: "description...",
    filterLabel: "Rank",
    filterName: "rank",
    type: "rankSelect",
  },
  {
    label: "Designation",
    description: "description...",
    filterLabel: "Designation",
    filterName: "designation",
    type: "designationSelect",
  },
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Email",
    description: "description...",
    filterLabel: "Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "From Date",
    description: "description...",
    filterLabel: "From Date",
    filterName: "joinFrom",
    type: "dateSelect",
    getTimestamp: true
  },
  {
    label: "Till Date",
    description: "description...",
    filterLabel: "Till Date",
    filterName: "joinTill",
    type: "dateSelect",
    getTimestamp: true
  },
  {
    label: "Time Zone",
    description: "description...",
    filterLabel: "Time Zone",
    filterName: "timezone",
    type: "timezoneSelect",
  },
  {
    label: "Country Code",
    description: "description...",
    filterLabel: "Country Code",
    filterName: "countryCode",
    type: "countrySelect",
    isd: true,
  },
  {
    label: "Phone",
    description: "description...",
    filterLabel: "Phone",
    filterName: "phone",
    type: "phone",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "residence.country",
    type: "countrySelect",
  },
  {
    label: "City",
    description: "description...",
    filterLabel: "City",
    filterName: "residence.city",
    type: "citySelect",
    dependency: {
      country: 'residence.country',
    },
    onlyCountry: true,
  },
  {
    label: "Is On App",
    description: "description...",
    filterLabel: "Is On App",
    filterName: "onApp",
    type: "checkBox",
  },
  {
    label: "ETS Enabled",
    description: "description...",
    filterLabel: "ETS Enabled",
    filterName: "ets",
    type: "checkBox",
  },
  {
    label: "Spot Enabled",
    description: "description...",
    filterLabel: "Spot Enabled",
    filterName: "etsSpot",
    type: "checkBox",
  },
  {
    label: "Tracking Enabled",
    description: "description...",
    filterLabel: "Traking Enabled",
    filterName: "tracking",
    type: "checkBox",
  },
  {
    label: "Location coordinate not near from address",
    description: "description...",
    filterLabel: "Location coordinate not near from address",
    filterName: "notNear",
    type: "checkBox",
  },
  {
    label: "Location not populated properly",
    description: "description...",
    filterLabel: "Location not populated properly",
    filterName: "showLocAbsent",
    type: "checkBox",
  },
  {
    label: "Within Hotspot",
    description: "description...",
    filterLabel: "Within Hotspot",
    filterName: "withinHotspot",
    type: "checkBox",
  },
];
