import { useRef, useState, useEffect } from "react";
import ReactCrop, {
    convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../util/setCanvasPreview";
import "./Profile.css";
import moment from "moment";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const ImageCropper = ({ closeModal, updateAvatar, setFileDetails, fileDetails }) => {
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState(null);
    const [error, setError] = useState("");
    const file = useRef({})

    const handleInputChange = (e) => {
        const selectedFile = e.target.files[0];
        processFile(selectedFile);
        file.current = selectedFile;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add("active");
    };

    const handleDragLeave = (e) => {
        e.target.classList.remove("active");
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.target.classList.remove("active");
        const droppedFile = e.dataTransfer.files[0];
        processFile(droppedFile);
    };

    const processFile = (selectedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const onImageLoad = (e) => {
        const img = e.currentTarget;
        const { clientWidth, clientHeight } = img;
        const maxHeight = window.innerHeight * 0.6;
        const ratio = clientWidth / clientHeight;
        let cropWidth, cropHeight;
        if (clientHeight > maxHeight) {
            cropHeight = maxHeight;
            cropWidth = cropHeight * ratio;
        } else {
            cropWidth = clientWidth;
            cropHeight = clientHeight;
        }
        const cropSize = Math.min(cropWidth, cropHeight);
        const cropX = (clientWidth - cropSize) / 2;
        const cropY = (clientHeight - cropSize) / 2;
        setCrop({
            unit: "px",
            x: cropX,
            y: cropY,
            width: cropSize,
            height: cropSize,
        });
    };


    useEffect(() => {
        if (!imgRef.current || !previewCanvasRef.current) return;
        const cropToUse = completedCrop || {
            x: 0,
            y: 0,
            width: imgRef.current.clientWidth,
            height: imgRef.current.clientHeight,
        };
        setCanvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            convertToPixelCrop(cropToUse, imgRef.current.clientWidth, imgRef.current.clientHeight)
        );
    }, [completedCrop]);


    const handleCropConfirm = () => {
        if (previewCanvasRef.current) {
            const dataUrl = previewCanvasRef.current.toDataURL();
            previewCanvasRef.current.toBlob(
                (blob) => {
                    if (blob) {
                        const croppedFile = new File([blob], file.current?.name ?? `${moment().unix()}.jpg`, {
                            type: blob.type,
                            lastModified: new Date().getTime(),
                        });
                        setFileDetails(croppedFile);
                    }
                },
                "image/jpeg",
                0.9
            );
            updateAvatar(dataUrl);
            closeModal();
        }
    };

    return (
        <>
            {!imgSrc ? (<div
                className="drag-area"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <>
                    <div className="icon">
                        <i className="fas fa-cloud-upload-alt"></i>
                    </div>
                    <header>Drag & Drop to Upload File</header>
                    <span>OR</span>
                    <button onClick={() => document.querySelector("#fileInput").click()}>
                        Browse File
                    </button>
                    <input
                        type="file"
                        id="fileInput"
                        hidden
                        accept="image/*"
                        onChange={handleInputChange}
                    />
                </>
            </div>) : null}
            {imgSrc && (
                <div className="crop-container">
                    <ReactCrop
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIMENSION}
                        minHeight={MIN_DIMENSION}
                        style={{ maxHeight: '60vh' }}
                    >
                        <img
                            ref={imgRef}
                            src={imgSrc}
                            alt="Upload"
                            className="crop-image"
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <button className="crop-button" onClick={handleCropConfirm}>
                        Done
                    </button>
                </div>
            )}
            {completedCrop && (
                <canvas
                    ref={previewCanvasRef}
                    className="crop-preview"
                    style={{
                        display: "none",
                        border: "1px solid black",
                        objectFit: "contain",
                        width: MIN_DIMENSION,
                        height: MIN_DIMENSION,
                    }}
                />
            )}
        </>
    );
};

export default ImageCropper;