import moment from "moment/moment";
export const isObjectValidate = (obj) => {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
}

export const dateFormat = (timeStamp, format = "'DD-MM-YYYY'") => {
    return moment.unix(timeStamp).format(format);
}

export const dateFormat2 = (timeStamp) => {
    return moment.unix(timeStamp).format('DD MMMM YYYY');
}

export const checkImageType = (imageUrl) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    const fileExtension = imageUrl.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension)
}


export const milliSecondsToSeconds = (timestamp) => {
    if (timestamp >= 1e12) {
        return moment(timestamp).unix();
    } else {
        return timestamp;
    }
}

export const getFormattedTime = (date) => moment(date, "YYYYMMDD").unix();

const phoneRegexes={
    "1": "^\\(?[2-9]\\d{2}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$",
    "20": "^\\d{9}$",
    "27": "^\\d{9}$",
    "30": "^\\d{10}$",
    "31": "^\\d{9}$",
    "32": "^\\d{8}$",
    "33": "^[1-9]\\d{8}$",
    "34": "^[689]\\d{8}$",
    "36": "^\\d{8}$",
    "39": "^\\d{9,10}$",
    "40": "^[23]\\d{8}$",
    "41": "^\\d{9}$",
    "43": "^\\d{10}$",
    "44": "^\\d{10}$",
    "45": "^\\d{8}$",
    "46": "^\\d{9}$",
    "47": "^\\d{8}$",
    "48": "^\\d{9}$",
    "49": "^\\d{10,11}$",
    "51": "^\\d{9}$",
    "52": "^\\d{10}$",
    "54": "^\\d{10}$",
    "55": "^\\d{10,11}$",
    "56": "^\\d{9}$",
    "57": "^\\d{10}$",
    "58": "^\\d{10}$",
    "60": "^\\d{9,10}$",
    "61": "^\\d{9}$",
    "62": "^\\d{9,12}$",
    "63": "^\\d{10}$",
    "64": "^\\d{8,9}$",
    "65": "^\\d{8}$",
    "66": "^\\d{9}$",
    "81": "^\\d{9,10}$",
    "82": "^\\d{9,10}$",
    "84": "^\\d{9,10}$",
    "86": "^\\d{11}$",
    "90": "^\\d{10}$",
    "91": "^\\d{10}$",
    "92": "^\\d{10}$",
    "93": "^\\d{9}$",
    "94": "^\\d{9}$",
    "95": "^\\d{8,9}$",
    "98": "^\\d{10}$",
    "212": "^\\d{9}$",
    "213": "^\\d{9}$",
    "216": "^\\d{8}$",
    "218": "^\\d{9}$",
    "220": "^\\d{7}$",
    "221": "^\\d{9}$",
    "222": "^\\d{8}$",
    "223": "^\\d{8}$",
    "224": "^\\d{9}$",
    "225": "^\\d{8}$",
    "226": "^\\d{8}$",
    "227": "^\\d{8}$",
    "228": "^\\d{8}$",
    "229": "^\\d{8}$",
    "230": "^\\d{8}$",
    "231": "^\\d{7,9}$",
    "232": "^\\d{8}$",
    "233": "^\\d{9}$",
    "234": "^\\d{10}$",
    "235": "^\\d{8}$",
    "236": "^\\d{8}$",
    "237": "^\\d{9}$",
    "238": "^\\d{7}$",
    "239": "^\\d{7}$",
    "240": "^\\d{9}$",
    "241": "^\\d{7,8}$",
    "242": "^\\d{9}$",
    "243": "^\\d{9}$",
    "244": "^\\d{9}$",
    "245": "^\\d{7}$",
    "248": "^\\d{7}$",
    "249": "^\\d{9}$",
    "250": "^\\d{9}$",
    "251": "^\\d{9}$",
    "252": "^\\d{8}$",
    "253": "^\\d{8}$",
    "254": "^\\d{9}$",
    "255": "^\\d{9}$",
    "256": "^\\d{9}$",
    "257": "^\\d{8}$",
    "258": "^\\d{9}$",
    "260": "^\\d{9}$",
    "261": "^\\d{9}$",
    "262": "^\\d{9}$",
    "263": "^\\d{9}$",
    "264": "^\\d{9}$",
    "265": "^\\d{9}$",
    "266": "^\\d{8}$",
    "267": "^\\d{8}$",
    "268": "^\\d{8}$",
    "269": "^\\d{7}$",
    "297": "^\\d{7}$",
    "298": "^\\d{6}$",
    "299": "^\\d{6}$",
    "350": "^\\d{8}$",
    "351": "^\\d{9}$",
    "352": "^\\d{9}$",
    "353": "^\\d{9}$",
    "354": "^\\d{7,9}$",
    "355": "^\\d{9}$",
    "356": "^\\d{8}$",
    "357": "^\\d{8}$",
    "358": "^\\d{9}$",
    "359": "^\\d{9}$",
    "370": "^\\d{8}$",
    "371": "^\\d{8}$",
    "372": "^\\d{7,8}$",
    "373": "^\\d{8}$",
    "374": "^\\d{8}$",
    "375": "^\\d{9}$",
    "376": "^\\d{6}$",
    "377": "^\\d{8,9}$",
    "378": "^\\d{6,10}$",
    "380": "^\\d{9}$",
    "381": "^\\d{9}$",
    "382": "^\\d{8}$",
    "383": "^\\d{8,9}$",
    "385": "^\\d{9}$",
    "386": "^\\d{8}$",
    "387": "^\\d{8}$",
    "389": "^\\d{8}$",
    "420": "^\\d{9}$",
    "421": "^\\d{9}$",
    "423": "^\\d{7}$",
    "500": "^\\d{5}$",
    "501": "^\\d{7}$",
    "502": "^\\d{8}$",
    "503": "^\\d{8}$",
    "504": "^\\d{8}$",
    "505": "^\\d{8}$",
    "506": "^\\d{8}$",
    "507": "^\\d{8}$",
    "509": "^\\d{8}$",
    "590": "^\\d{9}$",
    "591": "^\\d{8}$",
    "592": "^\\d{7}$",
    "593": "^\\d{9}$",
    "594": "^\\d{9}$",
    "595": "^\\d{9}$",
    "596": "^\\d{9}$",
    "597": "^\\d{7}$",
    "598": "^\\d{8}$",
    "599": "^\\d{7}$",
    "670": "^\\d{8}$",
    "672": "^\\d{6}$",
    "673": "^\\d{7}$",
    "674": "^\\d{7}$",
    "675": "^\\d{8}$",
    "676": "^\\d{7}$",
    "677": "^\\d{7}$",
    "678": "^\\d{7}$",
    "679": "^\\d{7}$",
    "680": "^\\d{7}$",
    "681": "^\\d{6}$",
    "682": "^\\d{5}$",
    "683": "^\\d{4}$",
    "685": "^\\d{5,7}$",
    "686": "^\\d{5}$",
    "687": "^\\d{6}$",
    "688": "^\\d{5}$",
    "689": "^\\d{6}$",
    "690": "^\\d{4}$",
    "691": "^\\d{7}$",
    "692": "^\\d{7}$",
    "850": "^\\d{9}$",
    "852": "^\\d{8}$",
    "853": "^\\d{8}$",
    "855": "^\\d{8,9}$",
    "856": "^\\d{8,9}$",
    "880": "^\\d{10}$",
    "886": "^\\d{9}$",
    "960": "^\\d{7}$",
    "961": "^\\d{8}$",
    "962": "^\\d{9}$",
    "963": "^\\d{9}$",
    "964": "^\\d{10}$",
    "965": "^\\d{8}$",
    "966": "^\\d{9}$",
    "967": "^\\d{9}$",
    "968": "^\\d{8}$",
    "970": "^\\d{9}$",
    "971": "^\\d{9}$",
    "972": "^\\d{9}$",
    "973": "^\\d{8}$",
    "974": "^\\d{8}$",
    "975": "^\\d{8}$",
    "976": "^\\d{8}$",
    "977": "^\\d{10}$",
    "992": "^\\d{9}$",
    "993": "^\\d{8}$",
    "994": "^\\d{9}$",
    "995": "^\\d{9}$",
    "996": "^\\d{9}$",
    "998": "^\\d{9}$"
  }

export const validatePhoneNumber = (phoneNumber, countryCode) => {
    // Remove any non-digit characters from the phone number
    // const cleanPhoneNumber = phoneNumber.toString().replace(/\D/g, '');

    const regex = new RegExp(phoneRegexes[countryCode]);

    if (!regex.test(phoneNumber)) {
        const expectedLength = phoneRegexes[countryCode].match(/\\d\{(\d+)(,\d+)?\}/)[1];
        return { error: true, message: `Invalid phone number. Expected length: ${expectedLength} digits for country code +${countryCode}` };
    }

    return { error: false, message: '' };
}

export const truncateDecimal = (number) => {
    if (isNaN(number)) return '--';
    if([null,undefined].includes(number)) return "--"
    if (Number.isInteger(number)) {
        return number.toString();
    }
    return number.toFixed(2);
};

export function formatTimeFromSeconds(seconds) {
    if (!seconds) {
        return '0 sec'
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hrs`;
    } else if (minutes > 0) {
        return `${formattedMinutes}:${formattedSeconds} mins`;
    } else {
        return `${formattedSeconds} sec`;
    }
}

export function formatTime(seconds) {
    if(seconds === 0) return "0 sec";
    if(!seconds) return "--"
    if (seconds < 60) {
        return `${seconds?.toFixed(2)} sec`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60).toFixed(2);
        return `${minutes} min`;
    } else {
        const hours = Math.floor(seconds / 3600).toFixed(2);
        return `${hours} hr`;
    }
}