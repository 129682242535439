import CloseIcon from "../../assets/icons/CloseIcon";
import ImageCropper from "./ImageCropper";
import "./Profile.css";

const ImageSelectionModal = ({ updateAvatar, closeModal, setFileDetails, fileDetails }) => {
    return (
        <div
            className="modal-i"
            aria-labelledby="crop-image-dialog"
            role="dialog"
            aria-modal="true"
        >
            <div className="modal-overlay"></div>
            <div className="modal-container">
                <div className="modal-content-i">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close-button-i"
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <ImageCropper updateAvatar={updateAvatar} closeModal={closeModal} setFileDetails={setFileDetails} fileDetails={fileDetails} />
                </div>
            </div>
        </div>
    );
};

export default ImageSelectionModal;