import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "toastr/build/toastr.min.css";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import moment from "moment";
import NotificationService from "./NotificationService";
import { InputField, Layout, SaveButton } from "../../components";
import { milliSecondsToSeconds } from "../../util/helper";
import Checkbox from "../Consultant/Checkbox";
import { Button } from "reactstrap";
import { IoNotifications } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import GeneralModal from "../../components/Common/GeneralModal";
import WriteEmail from "../../components/Quill/WriteEmail";
import { UserContext } from "../../store/context/userContext";

const Notification = () => {
    const { t } = useContext(I18nContext);
    const { showError, showMessage } = useContext(DialogContext);
    const { allSkills } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [selected_ids, setSelected_ids] = useState([]);
    const [filter, setFilter] = useState({
        userType: {
            label: "All Users",
            value: "user",
        },
        skill: {
            label: "All Skills",
            value: "",
        }
    })
    const [filePreviews, setFilePreviews] = useState([]);
    const [emailPopup, setEmailPopup] = useState(false);
    const [notifyPopup, setNotifyPopup] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [emailContent, setEmailContent] = useState({})
    const [name, setName] = useState("");

    const getConversationDetails = async () => {
        let filterOptions = {}
        if (filter?.loyalUsers) {
            filterOptions.loyalUsers = filter?.loyalUsers
        }
        if (filter?.windowShoppers) {
            filterOptions.windowShoppers = filter?.windowShoppers
        }
        try {
            const res = await NotificationService.fetchConversations(filterOptions)
            return res ?? []
        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async () => {
        if (loading) return;
        setSelected_ids([]);
        setLoading(true);
        let filterOptions = {}
        if (filter?.loyalUsers || filter?.windowShoppers) {
            const item = await getConversationDetails();
            if (item?.length > 0) filterOptions.ids = item
        }
        if (filter?.country) {
            filterOptions.country = filter?.country
        }
        if(name) {
            filterOptions.name = name;
        }
        if (filter?.userType?.value) {
            filterOptions.user = filter?.userType?.value
        }
        if (filter?.virginUsers) {
            filterOptions.virginUsers = filter?.virginUsers
        }
        if (filter?.newUsers) {
            filterOptions.newUsers = filter?.newUsers
        }
        if (filter?.loyalUsers) {
            filterOptions.loyalUsers = filter?.loyalUsers
        }
        if (filter?.windowShoppers) {
            filterOptions.windowShoppers = filter?.windowShoppers
        }
        if (filter?.staleUsers) {
            filterOptions.staleUsers = filter?.staleUsers
        }
        if (filter?.offline) {
            filterOptions.offline = filter?.offline
        }
        if (filter?.missedCalls) {
            filterOptions.missedCalls = filter?.missedCalls
        }
        if (filter?.disabled) {
            filterOptions.disabled = filter?.disabled
        }
        if (filter?.skill?.value) {
            filterOptions.skill = filter?.skill
        }
        try {
            await NotificationService.fetch(filterOptions)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [NotificationService?.page, NotificationService?.rows, filter]);

    const getFullNameWithSalutation = (client) => {
        const salutation = client?.salutation;
        const name = client?.name;
        const fname = client?.fname;
        const lname = client?.lname
        if (salutation != null) {
            const salutationText = salutation === 0 ? t("Mr.") : t("Miss.");
            return (!fname && !lname) ? (name ? name : "--") : `${salutationText} ${fname ? fname : ''} ${lname ? lname : ""} `;
        } else {
            return (!fname && !lname) ? (name ? name : '--') : `${fname ? fname : ''} ${lname ? lname : ""}`
        }
    };

    const changePagination = () => {
        if (NotificationService.page != 1) {
            NotificationService.page = 1;
        }
    }

    const sendMailHandler = async () => {
        if (sending) return;
        if (selected_ids?.length === 0) {
            showMessage("error", "Please select customers with valid email addresses");
            return;
        }
        const formData = new FormData();
        formData.append("email", JSON.stringify(selected_ids.map(e => e?.email).filter(Boolean)));
        formData.append("content", emailContent?.content);
        formData.append("subject", emailContent?.subject);
        emailContent?.files?.forEach((file, index) => {
            formData.append(`files`, file);
        });
        try {
            setSending(true);
            const res = await NotificationService.sendEmail(formData)
            if (res) {
                showMessage("success", "Success");
                setEmailPopup(false);
                setEmailContent({});
                setFilePreviews([]);
            }
            else
                showMessage("error", "Error");
        } catch (error) {
            showError(error)
        } finally {
            setSending(false);
        }
    }

    const sendNotificationHandler = async () => {
        if (sending) return;
        if (selected_ids?.length === 0) {
            showMessage("error", "Please select valid customers.");
            return;
        }
        try {
            setSending(true);
            const res = await NotificationService.sendNotification({
                message: notificationMessage,
                userIds: selected_ids?.map(e => e?._id).filter(Boolean),
                type: filter.userType.value === "user" ? 2 : 1,
            })
            if (res) {
                showMessage("success", "Success");
                setNotifyPopup(false);
                setNotificationMessage("");
            }
            else
                showMessage("error", "Error");
        } catch (error) {
            showError(error)
        } finally {
            setSending(false);
        }
    }

    const userTypeOptions = [
        {
            label: "All Users",
            value: "user",
            callback: (e) => {
                changePagination();
                setFilter({
                    ...filter,
                    userType: { ...filter?.userType, label: "All Users", value: "user", }
                })
            },
        },
        {
            label: "Consultants",
            value: "consultant",
            callback: (e) => {
                changePagination();
                setFilter({
                    ...filter,
                    userType: { ...filter?.userType, label: "Consultants", value: "consultant", }
                })
            },
        }
    ]

    const skillTypeOptions = [
        {
            label: "All Skills",
            value: "",
            callback: (e) => {
                changePagination();
                setFilter({
                    ...filter,
                    skill: {
                        label: "All Skills",
                        value: "",
                    }
                })
            },
        },
        ...allSkills?.map(e => ({
            label: e?.label,
            value: e?.value,
            callback: (e, label) => {
                changePagination();
                setFilter({
                    ...filter,
                    skill: {
                        label: label,
                        value: e,
                    }
                })
            },
        })),
    ]

    const handleFileSelected = (event) => {
        const files = Array.from(event.target.files);
        const imagePreviews = files
            .filter(file => file.type.startsWith("image/"))
            .map(file => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(file);
                });
            });
        Promise.all(imagePreviews).then((previews) => {
            setFilePreviews(previews);
        });
        setEmailContent((prevState) => ({
            ...prevState,
            files
        }));
    };

    const placeholder = () => {
        const val = filter?.userType?.value;
        if(selected_ids?.length === 0) {
            if(val === "user") return "No Users Selected";
            if(val === "consultant") return "No Consultants Selected";
        }
        else {
            if(val === "user") return "Users";
            if(val === "consultant") return "Consultants";
        }
    }

    const UserFilters = () => (
        <div className="d-flex align-items-center">
            <Checkbox
                checked={filter?.virginUsers}
                label="Virgin Users"
                onClick={(v) => setFilter({ ...filter, virginUsers: v.target.checked })}
            />
            <Checkbox
                checked={filter?.newUsers}
                label="New Users"
                onClick={(v) => setFilter({ ...filter, newUsers: v.target.checked })}
            />
            <Checkbox
                checked={filter?.loyalUsers}
                label="Loyal Users"
                onClick={(v) => setFilter({ ...filter, loyalUsers: v.target.checked })}
            />
            <Checkbox
                checked={filter?.windowShoppers}
                label="Window Shoppers"
                onClick={(v) => setFilter({ ...filter, windowShoppers: v.target.checked })}
            />
            <Checkbox
                checked={filter?.staleUsers}
                label="Stale Users"
                onClick={(v) => setFilter({ ...filter, staleUsers: v.target.checked })}
            />
        </div>
    )
    const ConsultantFilters = () => (
        <div className="d-flex align-items-center">

            <Checkbox
                checked={filter?.offline}
                label="Offline for more than 4 hours"
                onClick={(v) => setFilter({ ...filter, offline: v.target.checked })}
            />
            <Checkbox
                checked={filter?.missedCalls}
                label="Large Missed Calls"
                onClick={(v) => setFilter({ ...filter, missedCalls: v.target.checked })}
            />
            <Checkbox
                checked={filter?.disabled}
                label="Disabled"
                onClick={(v) => setFilter({ ...filter, disabled: v.target.checked })}
            />
        </div>
    )

    return (
        <>
            <Layout
                hideTitleBar
                filterUserType
                filterInput={true}
                setSearch1={setName}
                search1={name}
                userType={filter?.userType?.label}
                userTypeOptions={userTypeOptions}
                searchStatus={filter?.skill?.label}
                filterStatus={filter.userType.value === "consultant"}
                statusAction={skillTypeOptions}
                filterCountry
                country={filter?.country}
                setCountry={(country) => setFilter({ ...filter, country })}
                changePagination={changePagination}
                gridLoading={loading}
                fetchData={(d) => {
                    fetchData({...filter, ...d, filterType: '' });
                }}
                page={NotificationService.page}
                rows={NotificationService.rows}
                total={NotificationService.totalRecords}
                onPageChange={async (page, rows) => {
                    await NotificationService.onPaginationChange(page, rows);
                }}
                newFilterComponents={(
                    <div>
                        {filter?.userType?.value === "user" ? <UserFilters /> : <ConsultantFilters />}
                        <div className="mt-1 d-flex align-items-center justify-content-start">
                            <Button className="p-1 border-0 d-flex align-items-center" onClick={() => setNotifyPopup(true)}><IoNotifications className="me-1" /> Notify</Button>
                            <Button className="p-1 ms-2 bg-info border-0 d-flex align-items-center" onClick={() => setEmailPopup(true)}><MdEmail className="me-1" />Send Email</Button>
                        </div>
                    </div>
                )}
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>
                                <Checkbox
                                    checked={selected_ids.length === NotificationService.records.length}
                                    onClick={() => {
                                        if (selected_ids.length === 0)
                                            setSelected_ids(NotificationService.records.filter(Boolean));
                                        else
                                            setSelected_ids([])
                                    }}
                                />
                            </Th>
                            <Th>{t("Name")}</Th>
                            <Th>{t("Phone no.")}</Th>
                            <Th>{t("Email")}</Th>
                            <Th><div className="text-end">{t("Join Date")}</div></Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {NotificationService.records?.map((client, idx) => (
                            <Tr
                                key={idx}
                                style={{ marginLeft: "10px", }}
                            >
                                <Td>
                                    <Checkbox
                                        checked={selected_ids.includes(client)}
                                        onClick={() => {
                                            if (selected_ids.includes(client)) setSelected_ids(prev => prev.filter(e => e?._id !== client?._id))
                                            else setSelected_ids(prev => ([...prev, client]))
                                        }}
                                    />
                                </Td>
                                <Td>
                                    <div className="mt-2">
                                        {getFullNameWithSalutation(client)}
                                    </div></Td>
                                <Td>
                                    <div className="mt-2">
                                        {client?.phone ? `${client?.countryCode ? "+" + client?.countryCode : ""} ${client?.phone}` : "--"}
                                    </div>
                                </Td>
                                <Td>
                                    <div style={{ fontSize: "12px" }} className="mt-2">
                                        {client?.email}
                                    </div>
                                </Td>
                                <Td>
                                    <div className="mt-2">
                                        {client?.createdAt ? moment.unix(milliSecondsToSeconds(client?.createdAt)).format('DD-MM-YYYY') : '--'}
                                    </div>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>

            <GeneralModal
                visible={notifyPopup}
                setVisible={setNotifyPopup}
                contentStyle={{
                    height: 400,
                    width: 700
                }}
            >
                <h1 className="mb-3">{filter?.userType?.value === "consultant" ? "Notify Consultants" : "Notify Users"}</h1>
                <InputField
                    isDisabled
                    className="col-12"
                    placeholder={placeholder()}
                    value={selected_ids?.map(e => e?.fname).filter(Boolean).join(", ")}
                />
                <textarea
                    value={notificationMessage}
                    onChange={(v) => {
                        setNotificationMessage(v.target.value);
                    }}
                    placeholder="Description"
                    rows={6}
                    className="mt-3 col-12 p-2"
                    style={{ border: "1px solid #ced4da", outline: "none" }}
                />
                <SaveButton
                    style={{ right: "0%" }}
                    onClick={sendNotificationHandler}
                    label={"Send"}
                />
            </GeneralModal>

            {emailPopup && (
                <WriteEmail
                    sending={sending}
                    onClose={() => setEmailPopup(false)}
                    onEmailSend={sendMailHandler}
                    selectedEmails={selected_ids?.map(e => e?.email).filter(Boolean).join(', ')}
                    emailContent={emailContent}
                     setEmailContent ={setEmailContent}
                />
            )}


        </>
    );
}

export default observer(Notification);