import React, { useState, useEffect, useContext } from 'react'
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap'
import { SelectField } from '../../components'
import { useLocation, useNavigate } from "react-router-dom";
import ReviewService from './ReviewService';
import { DialogContext } from "../../store/context/DialogContext";
import ClientService from '../Clients/ClientService';
import ImageComponent from '../../components/Image/ImageComponent';
import CrossButton from '../../components/Buttons/CrossButton';
import { ThreeBounce } from 'better-react-spinkit';

const EditReviews = ({ setShowId, id }) => {
    const [data, setData] = useState({})
    const [custmer, setCustmer] = useState({})
    const cardStyle = {
        width: '25%',
        marginBottom: "40px",
        maxHeight: '84vh',
        overflowY: 'scroll'
    }
    const [loading, setLoading] = useState(false);
    const { showConfirm, showError } = useContext(DialogContext);
    const navigate = useNavigate();
    const recommend = [
        {
            label: "Yes",
            value: 1,
        },
        {
            label: "No",
            value: 0,
        }
    ]
    const fetch = async () => {
        setLoading(true);
        try {
            let review = {
            };
            let user = {};

            if (id) {
                review = await ReviewService.get(id);
                user = await ClientService.get(review?.customer_id)
                setCustmer(user)
                setData(review);
            }
        } catch (error) {

        }
        setLoading(false);
    };
    useEffect(() => {
        if (id) {
            setLoading(true);
            fetch();
        }
    }, [id]);
    const onApprove = async (event, id, val) => {
        event.stopPropagation();
        if (
            val === 1
                ? await showConfirm({
                    title: "Approve Review?",
                    description: "Are you sure you want to Approve Review?",
                })
                : await showConfirm({
                    title: "Reject Review?",
                    description: "Are you sure you want to Reject Review? ",
                })
        ) {
            try {
                const sendobj = {
                    status: val
                }
                await ReviewService.approve(id, sendobj);
                navigate('/review')
                fetch()
            } catch (e) {
                showError(e);
            }
        }
    };
    const service = [
        {
            label: "chat",
            value: 1,
        },
        {
            label: "Call",
            value: 2,
        },
        {
            label: "Video Call",
            value: 3,
        }
    ]
    const getstars = (val) => {
        let ans = "";
        for (let i = 0; i < val; i++)
            ans += "⭐️";
        return ans;
    }
    return (
        <>
            <Card
                className="p-3"
                style={cardStyle}>
                <div style={{ cursor: "pointer" }}>
                    <CrossButton onClick={() => { setShowId("") }} />
                </div>
                {loading ? <ThreeBounce
                    className="d-flex justify-content-center align-items-center h-100"
                    size={20}
                    color="#9F73AB" /> :
                    <CardBody className="py-3 px-5">
                        <Form>
                            <div>
                                <div className="d-flex">
                                    <div className="col-sm-3" style={{ width: "auto", borderRadius: "40px" }}>
                                        <ImageComponent source={custmer?.profileImage}
                                            style={{
                                                borderRadius: "45px",
                                                height: "45px",
                                                width: "35px"
                                            }}
                                        />
                                    </div>
                                    <h2 style={{ marginLeft: "10px" }}>
                                        <strong>
                                            {custmer?.name}
                                        </strong>
                                        <br />
                                    </h2>
                                </div>
                                <h2 className='mt-3'>
                                    <strong>
                                        Ratings
                                    </strong>
                                    <div>
                                        {getstars(data?.ratings)}
                                    </div>
                                </h2>
                            </div>
                            <Row className='mt-3'>
                                <h3>
                                    <strong>
                                        Review
                                    </strong>
                                </h3>
                                <textarea disabled={true} style={{ border: "1px solid #ced4da", outline: "none", height: "100px" }} value={data?.msg}>

                                </textarea>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <h3>
                                        <strong>
                                            Service
                                        </strong>
                                    </h3>
                                    <SelectField isDisabled={true} placeholder="Select" data={service} className="col-sm-8" value={data?.service} />
                                </Col>
                            </Row>
                            <Col className='mt-4'>
                                <h3>
                                    <strong>
                                        {/* would you recommend Astro Debeelaal Acharya to your friends ? */}
                                        would you recommend Astro {custmer?.name} to your friends ?
                                    </strong>
                                </h3>
                                <SelectField isDisabled={true} placeholder="Select" data={recommend} className="col-sm-8" value={data?.recommend} />
                            </Col>
                            <div className='d-flex justify-content-end mt-5'>
                                {data?.status == 2 || data?.status == 3 ? <Button style={{ background: "#FFCA59", marginRight: "5px", border: "none", color: "black", width: "80px" }}
                                    onClick={(event) => {
                                        onApprove(event, data?._id, 1);
                                    }}
                                >
                                    Approve
                                </Button> : <Button style={{ background: "#FFCA59", marginRight: "5px", border: "none", color: "black", width: "100px" }} >
                                    Approved
                                </Button>}
                                {data?.status == 1 || data?.status == 2 ? <Button style={{ background: "#FF8159", border: "none", color: "black", width: "100px" }}
                                    onClick={(event) => {
                                        onApprove(event, data?._id, 3);
                                    }}
                                >
                                    Reject
                                </Button> : <Button style={{ background: "#FF8159", border: "none", color: "black", width: "100px" }} >
                                    Rejected
                                </Button>}
                            </div>
                        </Form>
                    </CardBody>
                }
            </Card>
        </>
    )
}

export default EditReviews