import { makeAutoObservable } from "mobx";
import { doPOST } from "../../util/httpUtil";
import { ENDPOINTS } from "./NotificationConstant";
import moment from "moment";

class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 20;
    filterOption = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filter) {
        this.filterOption = filter
        const userFilter = filter?.user === "user";
        let url = `rows=${this.rows}&page=${this.page}`
        if(filter?.name) {
            url += `&fname=caseIgnore[${filter?.name}]`
        }
        let query = '';
        if (userFilter) {
            if (filter?.ids) {
                url += `&_id=in[${filter?.ids?.join(",")}]`
            }
            if (filter?.country) {
                url += `&country=${filter?.country}`
            }
            if (filter?.virginUsers) {
                url += `&createdAt=gt[${moment().subtract(7, 'days').unix()}]`
            }
            if (filter?.newUsers) {
                url += `&createdAt=gt[${moment().subtract(30, 'days').unix()}]`
            }
            if (filter?.windowShoppers) {
                url += `&createdAt=lt[${moment().subtract(60, 'days').unix()}]`
            }
            if (filter?.staleUsers) {
                url += `&appLastOpened=lt[${moment().subtract(60, 'days').unix()}]`
            }
        } else {
            if (filter?.offline) {
                url += `&lastOnlineAt=lt[${moment().subtract(4, 'hours').unix()}]`
            }
            if (filter?.missedCalls) {
                const ids = await this.fetchMissedCallConsultants();
                url += `&_id=in[${ids?.join(",")}]`;
            }
            if (filter?.disabled) {
                url += "&disable=true"
            }
            if (filter?.skill) {
                query = `?or=or[primarySkill=caseIgnore[${filter?.skill?.label}],coreSkill=${filter?.skill?.value}]`;
            }
        }
        const response = await doPOST(ENDPOINTS.userGrid(filter?.user, query), { filter: url });
        if (response.status === 200) {
            if (this.page === 1)
                this.totalRecords = response.data.data.total;
            this.records = response.data.data.rows;
            return response.data.data.rows;
        }
    };

    fetchConversations = async (filter = {}) => {
        let url = "rows=-1&status=2";
        if (filter?.loyalUsers) {
            url += `&stopAt=gt[${moment().subtract(30, 'days').unix()}]`
        }
        if (filter?.windowShoppers) {
            url += `&stopAt=gt[${moment().subtract(30, 'days').unix()}]`
        }
        const response = await doPOST(ENDPOINTS.conversationGrid, { filter: url })
        const reducedResult = response.data.data.rows.reduce((acc, obj) => {
            if (acc[obj.customer_id]) {
                acc[obj.customer_id] += 1;
            } else {
                acc[obj.customer_id] = 1;
            }
            return acc;
        }, {});
        return Object.entries(reducedResult).map(([key, val]) => {
            if (filter?.loyalUsers && val >= 5) return key;
            else if (filter?.windowShoppers && val < 15) return key;
        }).filter(Boolean)
    }

    fetchMissedCallConsultants = async () => {
        let url = `rows=-1&status=in[3,5]&requestedAt=gt[${moment().subtract(1, "days").unix()}]`;
        const res = await doPOST(ENDPOINTS.conversationGrid, { filter: url });
        const ids = res.data?.data?.rows?.map(e => e?.consultant_id);
        const uniqueIds = [...new Set(ids)];
        return uniqueIds ?? [];
    }

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        this.records = []
        await this.fetch(this.filterOption);
    };

    sendEmail = async (data) => {
        const response = await doPOST(ENDPOINTS.sendEmail, data)
        if (response.status) return true
        else return false
    }
    sendNotification = async (data) => {
        const response = await doPOST(ENDPOINTS.sendNotification, data)
        if (response.status) return true
        else return false
    }
}

const NotificationService = new Service();
export default NotificationService;