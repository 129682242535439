import React, { useContext, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Button, Input } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import MinimizableComponentEmail from '../MinimizableComponentEmail/MinimizableComponentEmail';
import { ThreeBounce } from 'better-react-spinkit';

const WriteEmail = ({ onClose, onEmailSend, selectedEmails, emailContent = {}, setEmailContent, sending }) => {
    const { showError, showMessage } = useContext(DialogContext);
    const quillRef = useRef(null);

    useEffect(() => {
        if (selectedEmails) {
            setEmailContent(prev => ({
                ...prev,
                to: selectedEmails || '',
            }));
        }
    }, [selectedEmails, setEmailContent]);

    const handleInputChange = (field, value) => {
        setEmailContent(prev => ({ ...prev, [field]: value }));
    };

    const handleFileUpload = event => {
        const newFiles = Array.from(event.target.files);
        setEmailContent(prev => ({
            ...prev,
            files: [...(prev.files || []), ...newFiles],
        }));
    };

    const handleRemoveFile = index => {
        setEmailContent(prev => ({
            ...prev,
            files: prev.files.filter((_, i) => i !== index),
        }));
    };

    const handleSendMail = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // if (!emailRegex.test(emailContent.to)) return showError('Invalid email address');
        if (!emailContent.subject?.trim()) return showError('Subject is missing');
        if (!emailContent.content?.trim()) return showError('Email body is missing');

        if (onEmailSend) {
            await onEmailSend();
        }
    };

    return (
        <MinimizableComponentEmail onClose={onClose} title="Compose Email">
            {/* Recipient Email */}
            <div className="mb-3">
                <Input
                    type="email"
                    placeholder="Recipient Email"
                    value={emailContent.to || ''}
                    onChange={e => handleInputChange('to', e.target.value)}
                />
            </div>

            {/* Subject */}
            <div className="mb-3">
                <Input
                    type="text"
                    placeholder="Subject"
                    value={emailContent.subject || ''}
                    onChange={e => handleInputChange('subject', e.target.value)}
                />
            </div>

            {/* Email Body */}
            <div>
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={emailContent.content || ''}
                    onChange={value => handleInputChange('content', value)}
                    placeholder="Write your email here..."
                    style={{ height: '120px' }}
                />
            </div>

            {/* Attachment Upload */}
            <div className="mt-5 border border-1">
                <h4>Attachments</h4>
                <Input type="file" multiple onChange={handleFileUpload} />
                <ul className="mt-2" style={{height:"180px", overflow:"auto"}}>
                    {emailContent.files?.map((file, index) => (
                        <li key={index} className="d-flex align-items-center mt-2">
                            <span className="col-8">{file.name}</span>
                            <Button color="danger" size="sm" onClick={() => handleRemoveFile(index)}>
                                X
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Actions */}
            <div className="d-flex justify-content-end" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                <Button color="secondary" onClick={onClose} className="me-2">
                {sending ? <ThreeBounce color="#fff"/> : "Cancel"} 
                </Button>
                <Button color="success" onClick={handleSendMail}>
                   {sending ? <ThreeBounce color="#fff"/> : "Send"} <i className="fab fa-telegram-plane ms-1"></i>
                </Button>
            </div>
        </MinimizableComponentEmail>
    );
};

export default WriteEmail;
