import { useEffect, useRef, useState } from "react";
import PencilIcon from "../../assets/icons/PencilIcon";
import ImageSelectionModal from "./ImageSelectionModal";
import "./Profile.css";
import imageplaceholder from '../../assets/images/imagplaceholder.png'
import axios from "axios";
import { ENDPOINTS } from "./ImageConstant";
import { Buffer } from 'buffer';

const ProfileSelect = ({ setFileDetails, source, fileDetails }) => {
  const [imgSrc, setImgSrc] = useState(imageplaceholder);
  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar = (imgSrc) => {
    setImgSrc(imgSrc);
  };

  const fetchImage = async () => {
    try {
      const response = await axios.get(ENDPOINTS.staticFileUrl(source), {
        responseType: 'arraybuffer',
        headers: {
          platform: 'browser'
        }
      });
      let dataUri;
      if (response?.data?.byteLength === 0) {
        dataUri = imageplaceholder;
      } else {
        const contentType = response.headers['content-type'];
        const imageData = Buffer.from(response.data, 'binary').toString('base64');
        dataUri = `data:${contentType};base64,${imageData}`;
      }
      setImgSrc(dataUri);
    } catch (error) {
      console.error('Error fetching image:', error);
      setImgSrc(imageplaceholder);
    }
  };

  useEffect(() => {
    if (!!source)
      fetchImage();
  }, [source]);

  return (
    <div className="profile-container">
      <div className="avatar-wrapper">
        <img
          src={imgSrc}
          alt="Avatar"
          className="avatar"
        />
        <button
          className="edit-button"
          title="Change photo"
          onClick={() => setModalOpen(true)}
        >
          <PencilIcon />
        </button>
      </div>
      {modalOpen && (
        <ImageSelectionModal
          updateAvatar={updateAvatar}
          closeModal={() => setModalOpen(false)}
          setFileDetails={setFileDetails}
          fileDetails={fileDetails}
        />
      )}
    </div>
  );
};

export default ProfileSelect;