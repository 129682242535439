import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import { Nav } from "reactstrap";
import {
  CardThreeBounce,
  Layout,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext.js";
import ReviewService from "./ReviewService";
import DropDown from "../../components/DropDown/DropDown";
import EditReviews from "./EditReviews.js";
import ReviewComponent from "./ReviewComponent";

const Review = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [requested, setRequested] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [drp_primary1, setDrp_primary1] = useState(false);

  const [formattedToDate, setFormattedToDate] = useState();
  const [formattedFromDate, setFormattedFromDate] = useState();
  const [showId, setShowId] = useState("")

  const [type, setType] = useState("requested")
  const { showError, showConfirm } = useContext(DialogContext);
  const { setShowLoader } = useContext(UserContext);
  const navigate = useNavigate()

  const formatDate = (inputDate = 0) => {
    const dateString = inputDate.toString();
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [status, setStatus] = useState({
    label: "Requested",
    value: "requested",
  });

  const statusOptions = [
    {
      icon: "",
      label: "Requested",
      color: "black",
      value: "requested",
      callback: (e) => {
        changePagination();
        setStatus({
          ...status,
          label: "Requested",
          value: "requested"
        })
        changeType("requested");
      },
    },
    {
      icon: "",
      label: "Approved",
      color: "green",
      value: "approved",
      callback: (e) => {
        changePagination();
        setStatus({
          ...status,
          label: "Approved",
          value: "approved"
        })
        changeType("approved")
      },
    },
    {
      icon: "",
      label: "Rejected",
      color: "red",
      value: "rejected",
      callback: (e) => {
        changePagination();
        setStatus({
          ...status,
          label: "Rejected",
          value: "rejected"
        })
        changeType("rejected")
      },
    },
  ]


  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      if (fromDate || toDate) {
        filter.fromDate = formattedFromDate;
        filter.tillDate = formattedToDate;
      }
      if (type == "approved") {
        const response = await ReviewService.fetch(filter);
        setData(response?.rows)
      } else if (type == "requested") {
        const requesteddata = await ReviewService.fetchrequested(filter);
        setRequested(requesteddata?.rows)
      } else if (type == "rejected") {
        const rejectedddata = await ReviewService.fetchrejected(filter);
        setRejected(rejectedddata?.rows)
      }
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [type, ReviewService?.rows, ReviewService?.page]);

  const changeType = (type) => {
    ReviewService.resetData()
    setType(type);
    setShowId("")
  }

  useEffect(() => {
    setFormattedFromDate(formatDate(fromDate));
    setFormattedToDate(formatDate(toDate));

  }, [fromDate, toDate]);


  const onApprove = async (event, id, val) => {
    event.stopPropagation();
    if (
      val === 1
        ? await showConfirm({
          title: "Approve Review?",
          description: "Are you sure you want to Approve Review?",
        })
        : await showConfirm({
          title: "Reject Review?",
          description: "Are you sure you want to Reject Review? ",
        })
    ) {
      try {
        const sendobj = {
          status: val
        }
        await ReviewService.approve(id, sendobj);

        navigate('/review')
      } catch (e) {
        showError(e);
      }
    }
  };
  const getdate = (val) => {
    console.log(val);
    const dateTime = new Date(val);
    const formattedDateTime = dateTime.toLocaleString();
    return formattedDateTime
  }
  const changePagination=()=>{
    if(ReviewService.page!=1){
      ReviewService.page=1;
    }
  }

  if (loading) return <CardThreeBounce />;
  return (
    <>

      <Layout
        changePagination={changePagination}
        hideTitleBar
        gridLoading={loading}
        // navbar={true}
        filterInput={true}
        hideNameInput={true}
        searchStatus={status.label}
        statusAction={statusOptions}
        showFilterLabel
        filterStatus
        showFromToDateFilter
        fromToPlaceholder={["From", "To"]}
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        setFromDate={setFromDate}
        rows={ReviewService.rows}
        total={ReviewService.totalRecords}
        showViewPage={showId}
        viewSection={<EditReviews setShowId={setShowId} id={showId} />}
        page={ReviewService.page}
        fetchData={fetchData}
        onPageChange={async (page, rows) => {
          await ReviewService.onPaginationChange(page, rows, type);
        }}
      >
        {/* <Layout.NavLinks>
          <div className="d-flex">
            <span className="mt-2 mx-2" style={{ fontSize: "14px", fontWeight: "500" }}>Status :</span>
            <DropDown
              drp_primary={drp_primary1}
              setDrp_primary={setDrp_primary1}
              filterName={"OrderType"}
              label={status?.label}
              action={statusOptions}
              className={"btn  bg-white "}
              borderRadius={"12px"}
              style={{ border: "1px solid", fontWeight: "500", fontSize: "14px", color: status?.value === "requested" ? "black" : (status?.value === "approved" ? "green" : "red") }}
              menuStyle={{ fontWeight: "500", fontSize: "14px", }}
            />
          </div>
          <Nav className="mr-auto px-4 text-dark" style={{ fontSize: "17px", color: "black" }}>
            <Link style={{ fontSize: "17px", color: "black", borderBottom: type == "requested" ? "2px solid black" : "" }} className="px-4 " to="/review" onClick={() => changeType("requested")}>Requested</Link>
            <Link style={{ fontSize: "17px", color: "black", borderBottom: type == "approved" ? "2px solid black" : "" }} className="px-4 " to="/review" onClick={() => changeType("approved")}>Approved</Link>
            <Link style={{ fontSize: "17px", color: "black", borderBottom: type == "rejected" ? "2px solid black" : "" }} className="px-4 " to="/review" onClick={() => changeType("rejected")}>Rejected</Link>
          </Nav>
        </Layout.NavLinks> */}
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>Customer Name</Th>
              <Th>Consultant Name</Th>
              <Th>Review</Th>
              <Th>Date Time</Th>
              <Th>Service</Th>
              <Th>Ratings</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {type === "approved" && data?.map((ele, i) => {
              return (
                <ReviewComponent
                  setShowId={setShowId}
                  data={data}
                  onApprove={onApprove}
                  index={i}
                  review={ele}
                  action="Reject"
                  style={{
                    background: "white",
                    color: "rgb(255, 129, 89)",
                    padding: "3px",
                    margin: "5px",
                    borderColor: "rgb(255, 129, 89)"
                  }} />
              )
            })}
            {type === "requested" && requested?.map((ele, i) => {
              return (
                <ReviewComponent
                  setShowId={setShowId}
                  data={requested}
                  onApprove={onApprove}
                  index={i}
                  review={ele}
                />
              )
            })}
            {type === "rejected" && rejected?.map((ele, i) => {
              return (
                <ReviewComponent
                  setShowId={setShowId}
                  data={rejected}
                  onApprove={onApprove}
                  index={i}
                  review={ele}
                  action="Approve"
                  style={{
                    background: "white",
                    color: "black",
                    padding: "3px 5px",
                    margin: "5px",
                    border: "none",
                    backgroundColor: "rgb(255, 202, 89)"
                  }} />
              )
            })}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </>
  );
};

export default observer(Review);
