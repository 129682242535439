import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CrossButton } from '../../components';
import { doPOST } from '../../util/httpUtil';
import { ENDPOINTS } from './ConsultantConstant';
import { ThreeBounce } from 'better-react-spinkit';
import { IoMdCloudDownload } from "react-icons/io";
import { formatTimeFromSeconds, truncateDecimal } from '../../util/helper';
import ConsultantService from './ConsultantService';
import moment from 'moment';
import { Button } from 'reactstrap';
import DateRange from '../../components/DateSelects/DateRange';
import { FaSearchLocation } from 'react-icons/fa';

const SummaryAll = ({ }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
    const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))

    const closeModal = () => {
        setVisible(false);
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const res = await doPOST(ENDPOINTS.summaryAll(''), {
                fromDate: moment(fromDate, "YYYYMMDD").startOf('day').unix(),
                tillDate: moment(tillDate, "YYYYMMDD").endOf('day').unix()
            });
            setData(res?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
  

        if (visible) {
            setData([]);
            fetchData();
        }
    }, [visible])

    const downloadSummaryExcel = async () => {
        try {
            setLoading(true);
            const consultants = ConsultantService?.records?.map(e => ({ displayName: e?.displayName, _id: e?._id }))
            const response = await doPOST(ENDPOINTS.summaryAll(`?forDownload=true`), { 
                fromDate: moment(fromDate, "YYYYMMDD").startOf('day').unix(),
                tillDate: moment(tillDate, "YYYYMMDD").endOf('day').unix()
             });
            console.log(response.data?.data);
            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
            const link = document.createElement('a');
            link.href = csvContent;
            link.target = '_blank';
            link.download = `consultants_summary.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const bgClass = { background: "#d8dadc" };

    return (
        <>
            <Button className="btn btn-success p-1 border-0 d-flex align-items-center" onClick={() => setVisible(true)}>Earnings Report</Button>
            <Modal
                isOpen={visible}
                onRequestClose={closeModal}
                contentLabel="Modal"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    },
                    content: {
                        margin: 'auto',
                        borderRadius: "16px",
                        // height: 550
                    },
                }}
            >
                <div className='mb-3 d-flex align-items-center gap-3'>
                    <DateRange
                        placeholder={"Select Date Range"}
                        allowClear={false}
                        defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                        disabled={[false, false]}
                        onChange={(v) => {
                            if (v === null) {
                                setFromDate(null);
                                setTillDate(null);
                            } else {
                                const formattedFromDate = moment.unix(v[0]).format('YYYYMMDD');
                                const formattedTillDate = moment.unix(v[1]).format('YYYYMMDD');
                                setFromDate(formattedFromDate);
                                setTillDate(formattedTillDate);
                            }
                        }}
                    />
                    <div>
                        <Button
                            style={{
                                borderRadius: "25px",
                                backgroundColor: "#D68910",
                                border: "none",
                                padding: "4px 9px",
                                fontSize: "18px",
                            }}
                            className="uil-search "
                            id="searchButtonId"
                            onClick={fetchData}>

                        </Button>
                    </div>
                </div>
                <div style={{ cursor: "pointer" }}>
                    <CrossButton onClick={closeModal} style={{ top: 16 }} />
                    <IoMdCloudDownload size={26} style={{ float: "right", position: "relative", right: 20, bottom: 5 }} onClick={() => { downloadSummaryExcel() }} />
                </div>
                <h4 style={{ fontWeight: '600', fontSize: "18px" }}>Consultants Summary</h4>
                {
                    loading ? <ThreeBounce /> : (
                        <table cellPadding={5} className='mt-4 table table-striped' style={{ border: '0px solid grey', borderRadius: "15px", width: '100%' }}>
                            <thead >
                                <tr>
                                    <th style={bgClass}>Consultant</th>
                                    <th style={bgClass}>Total Transactions</th>
                                    <th style={bgClass}>Voice Transactions</th>
                                    <th style={bgClass}>Chat Transactions</th>
                                    <th style={bgClass}>Missed Calls</th>
                                    <th style={bgClass}>Missed Call Percentage</th>
                                    {/* <th style={bgClass}>Average Call Duration</th> */}
                                    <th style={bgClass}>Unique Users</th>
                                    <th style={bgClass}>Repeat Users</th>
                                    {/* <th style={bgClass}>Total Online Duration</th> */}
                                    <th style={bgClass}>Average Response Time</th>
                                    <th style={bgClass}>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map(e => (
                                        <tr>
                                            <td>{e?.consultant_name}</td>
                                            <td>{truncateDecimal(e?.totalTransactions)}</td>
                                            <td>{truncateDecimal(e?.voiceTransactions)}</td>
                                            <td>{truncateDecimal(e?.chatTransactions)}</td>
                                            <td>{truncateDecimal(e?.missedCalls)}</td>
                                            <td>{truncateDecimal(e?.missedCallPercentage)}%</td>
                                            {/* <td>{formatTimeFromSeconds(e?.avgCallDuration)}</td> */}
                                            <td>{truncateDecimal(e?.uniqueUsers)}</td>
                                            <td>{truncateDecimal(e?.repeatUsers)}</td>
                                            {/* <td>{formatTimeFromSeconds(e?.totalOnlineDuration)}</td> */}
                                            <td>{formatTimeFromSeconds(e?.avgResponseTime)}</td>
                                            <td>{truncateDecimal(e?.amount)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    )
                }
            </Modal>
        </>
    )
}

export default SummaryAll;