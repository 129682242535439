import React, { useState, useEffect, useContext } from "react";
import { Td, Tr } from "react-super-responsive-table";
import { Button } from "reactstrap";
import ClientService from "../Clients/ClientService";
import ConsultantService from "../Consultant/ConsultantService";
import ImageComponent from "../../components/Image/ImageComponent";
import { DialogContext } from "../../store/context/DialogContext";
import { ThreeBounce } from "better-react-spinkit";
import { FaEye } from "react-icons/fa";
import moment from "moment/moment";


const ReviewComponent = ({ onApprove, index, review, data, action, style, setShowId }) => {

    const [user, setUser] = useState(null);
    const [consultantName, setConsultantName] = useState("");
    const [loading, setLoading] = useState(false)


    const { showError } = useContext(DialogContext);

    const getstars = (val) => {
        let ans = "";
        let count = 0;
        for (let i = 0; i < val; i++) {
            ans += "⭐️";
            count++;
        }
        return ans;
    }

    const getServicetype = (val) => {
        if (val == 1)
            return "Chat"
        if (val == 2)
            return "Call"
        if (val == 2)
            return "Video Call"

    }


    const fetchUser = async () => {
        setLoading(true)
        try {
            const response = await ClientService.get(review?.customer_id);
            const responseConsultant = await ConsultantService.get(review?.consultant_id);
            setConsultantName(responseConsultant?.displayName)
            setUser(response)
        } catch (e) {
            showError(e);
        }
        setLoading(false)
    };


    useEffect(() => {
        fetchUser();
    }, [review?.customer_id])

    return <Tr key={index}>
        <Td>
            <div className="d-flex ">
                <ImageComponent source={review?.customerDetails?.profileImage} style={{
                    borderRadius: "35px",
                    height: "35px",
                    width: "35px"
                }} />
                <div className="px-2 align-self-center">
                    {loading ?
                        <ThreeBounce size={8} color="#9F73AB" />
                        :
                        <>
                            <strong>
                                {user?.fname}
                            </strong>
                            {review.anonymous ? " (Anonymous)" : ""}
                        </>
                    }
                </div>
            </div>
        </Td>
        <Td>
            <div className="px-2 align-self-center">
                {loading ?
                    <ThreeBounce size={8} color="#9F73AB" />
                    :
                    <>
                        <strong>
                            {consultantName}
                        </strong>
                    </>
                }
            </div>
        </Td>
        <Td>
            <div>
                {review?.msg ? review?.msg : '--'}
            </div>
        </Td>
        <Td>{review?.at ? moment.unix(review?.at).format("DD-MM-YYYY HH:MM") : "--"}</Td>
        <Td>{review?.service ? getServicetype(review?.service) : '--'}</Td>
        <Td>{getstars(review?.ratings)}</Td>
        <Td className="float-start d-flex">
            <div style={{
                color: "black",
                padding: "3px",
                marginRight: "3px",
                cursor: 'pointer',
                marginTop: "9px"
            }}
                onClick={
                    () => {
                        setShowId(null);
                        setShowId(review?._id)
                    }
                }>
                <FaEye size={16} />
            </div>
            {(action == "Approve" || action == "Reject") && <Button onClick={(event) => {
                onApprove(event, review?._id, 1);
            }} style={{ ...style }}>{action}</Button>}
        </Td>
    </Tr>
};

export default ReviewComponent;
