import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, NavbarText, Row, Table } from "reactstrap";
import Modal from 'react-modal';
import {
  DateSelect,
  InputField,
  SaveButton,
  SelectField,
  PhoneNum,
  SelectFieldMultiple,
  CrossButton,
} from "../../components";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import ThreeBounceLoader from "../../components/Loader/ThreeBounceLoader.js";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext.js";
import ConsultantService from "./ConsultantService";
import DocumentDownload from "./Download";
import BankDetailComponent from "../../components/BankDetailComponent";
import Checkbox from "./Checkbox";
import CountrySelect from "../../components/CountrySelect/CountrySelect";
import imageplaceholder from "../../assets/images/imagplaceholder.png";
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import ProfileSelect from "../../components/Image/Profile.jsx";
import DropDown from "../../components/DropDown/DropDown";
import "./style.css";
import { isArray, isObject } from "lodash";
import { validatePhoneNumber } from "../../util/helper.js";
import { doPATCH, doPOST } from "../../util/httpUtil.js";

const EditConsultant = (props) => {
  const [data, setData] = useState({
    salutation: 0,
    countryCode: 91,
    bankDetails: []
  });
  const [isDisabled, setIsDisabled] = useState();
  const [languagesArray, setLanguagesArray] = useState([{}]);
  const [tagsArray, setTagsArray] = useState([{}]);
  const [drpStatus, setDrpStatus] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState();
  // const [isApproved, setIsApproved] = useState(data?.isApproved != null ? data?.isApproved : null);
  const [password, setPassword] = useState({});
  const [bankDetailsError, setBankDetailsError] = useState([]);
  const [skillsArray, setSkillsArray] = useState([{}]);
  const navigate = useNavigate();
  const [status, setStatus] = useState({
    label: "Status",
    value: null,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { showMessage, showError, showConfirm, showAlert } =
    useContext(DialogContext);
  const { setShowLoader, skills, languages, tags, allSkills } = useContext(UserContext);
  const getDetails = async () => {
    try {
      setShowLoader(true);
      let response = {};
      if (id) response = await ConsultantService.get(id);
      setData({...response,displayName:response?.displayName??response?.fname});
      setStatus({
        ...status,
        label: response?.isApproved ? "Approve" : "Disapprove",
        value: response?.isApproved ? 1 : 0
      });
      setIsDisabled(response?.disable);
      return response?._id ?? ""
    } catch (error) { }
    finally {
      setShowLoader(false);
    }
  };

  const onApprove = async (val) => {
    // event.stopPropagation();
    const userApproved = {
      val,
    };

    val === 1
      ? await showConfirm({
        title: "Approve Consultant?",
        description: "Are you sure you want to approve this Consultant?",
      })
      : await showConfirm({
        title: "Unapprove Consultant?",
        description: "Are you sure you want to Unapprove this Consultant?",
      })

    // {
    //   try {
    //     await ConsultantService.approve(id, userApproved);
    //     getDetails();

    //     showMessage("info", "Consultant Approved  Successfully");
    //     // fetchData();
    //   } catch (e) {
    //     showError(e);
    //   }
    // }
  };
  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);




  const addBankDetailRow = () => {
    setData((prevState) => ({
      ...prevState,
      bankDetails: [...prevState.bankDetails, { accountNumber: '', ifsc: '', bankName: '', holderName: '' }],
    }));
  };


  // const fetchLanguages = async () => {
  //   try {
  //     const response = await ConsultantService.getLanguages();
  //     if(response?.length){
  //       let fetchedLanguages=[];
  //       response?.map((language)=>{
  //         fetchedLanguages.push({
  //        label:language?.name?language?.name:"",
  //         value:language?.code?language?.code:"",
  //      })
  //       })
  //       setLanguages([...fetchedLanguages])
  //     }
  //   } catch (e) {
  //     showError(e);
  //   }
  // };
  // const fetchTags = async () => {
  //   try {
  //     const response = await ConsultantService.getTags();
  //     if(response?.length){
  //       let fetchedTags=[];
  //       response?.map((tag)=>{
  //         fetchedTags.push({
  //        label:tag?.name?tag?.name:"",
  //         value:tag?.code?tag?._id:"",
  //      })
  //       })
  //       setTags([...fetchedTags])
  //     }
  //   } catch (e) {
  //     showError(e);
  //   }
  // };
  // const languages = [
  //   {
  //     label: "English",
  //     value: "eng",
  //   },
  //   {
  //     label: "Hindi",
  //     value: "hi",
  //   },
  //   {
  //     label: "French",
  //     value: "fre",
  //   },
  //   {
  //     label: "Russian",
  //     value: "rus",
  //   },
  //   {
  //     label: "Chinese",
  //     value: "ch",
  //   },
  //   {
  //     label: "German",
  //     value: "ge",
  //   },
  //   {
  //     label: "Turkey",
  //     value: "tur",
  //   },
  //   {
  //     label: "Japan",
  //     value: "jap",
  //   },
  //   {
  //     label: "Brazil",
  //     value: "br",
  //   },
  // ];
  // const skillarray = skills.map(skill => ({
  //   label: skill,
  //   value: skill,
  // }));
  // const skillarray = [
  //   {
  //     label: "Numerology",
  //     value: "Numerology",
  //   },
  //   {
  //     label: "Horoscope",
  //     value: "Horoscope",
  //   },
  //   {
  //     label: "Astronomy",
  //     value: "Astronomy",
  //   },
  //   {
  //     label: "Astrological counseling",
  //     value: "Astrological counseling",
  //   },
  //   {
  //     label: "Astrological forecasting",
  //     value: "Astrological forecasting",
  //   },
  //   {
  //     label: "Horoscope interpretation",
  //     value: "Horoscope interpretation",
  //   }, ,
  //   {
  //     label: "Western Astrology",
  //     value: "Western Astrology",
  //   },
  // ];
  const qualification1 = [
    { value: "1", label: "High School" },
    { value: "2", label: "Graduate" },
    { value: "3", label: "Post Graduate" },
    { value: "4", label: "Phd Scholar" },
  ];

  const qualification = [
    { value: "High School", label: "High School" },
    { value: "Graduate", label: "Graduate" },
    { value: "Post Graduate", label: "Post Graduate" },
    { value: "Phd Scholar", label: "Phd Scholar" },
  ];
  const experience = [
    {
      label: "1 year",
      value: 1,
    },
    {
      label: "2 year",
      value: 2,
    },
    {
      label: "3 year",
      value: 3,
    },
    {
      label: "4 year",
      value: 4,
    },
    {
      label: "5 year",
      value: 5,
    },
  ];
  const salutation = [
    {
      value: 0,
      label: "Mr.",
    },
    {
      value: 1,
      label: "Mrs.",
    },
    {
      value: 2,
      label: "Miss.",
    },
  ];

  const StatusOptions = [
    {
      icon: "",
      label: "Approve",
      value: 1,
      callback: (e) => {
        setData({
          ...data,
          isApproved: true,
        })
        setStatus({
          ...status,
          label: "Approve",
          value: 1
        })
      },
    },
    {
      icon: "",
      label: "Dissaprove",
      value: 0,
      callback: (e) => {
        setData({
          ...data,
          isApproved: false,
        })
        setStatus({
          ...status,
          label: "Dissaprove",
          value: 0
        })

      },
    },
  ]
  // const updateDisable = async (isDisabled, value) => {
  //   try {
  //     setShowLoader(true);
  //     let response;
  //     if (id) {
  //       response = await ConsultantService.update({ disable: isDisabled }, id);
  //     } else {
  //       response = await ConsultantService.save(data);
  //     }
  //     console.log(response);
  //     // getDetails();
  //   } catch (err) {
  //     showError(err?.message);
  //   }
  //   const userApproved = {
  //     value,
  //   };
  //   if (id) {
  //     try {
  //       await ConsultantService.approve(id, userApproved);
  //       getDetails();
  //       showMessage("info", "Consultant Approved  Successfully");
  //       // fetchData();
  //     } catch (e) {
  //       showError(e);
  //     }
  //   }
  //   setShowLoader(false);
  // };
  const submitForm = async () => {
    
    try {
      if(!data?.country){
        showError('Please select country');
        return
      }
      if ((bankDetailsError.length > 0 && data?.bankDetails.length > 0) ) {
        showError('please fill all required fields');
        return
      }
      if (!data?.email) {
        showError('Please enter the email');
        return
      }
      if (!data?.dob) {
        showError('Please enter the date of birth');
        return
      }
      if (!data?.fname) {
        showError('Please enter the name');
        return
      }
      if (!data?.displayName) {
        showError('Please enter the display name for consultant');
        return
      }
      // if (!data?.primarySkill) {
      //   showError('Please enter the primary skill');
      //   return;
      // }
      if (!data?.educationQualification) {
        showError('Please enter the education qualification');
        return;
      }
      if ([null,undefined].includes(data?.experience)) {
        showError('Please enter the experience');
        return;
      }
      if(!data.languages?.length){
        showError('Please enter the languages');
        return;
      }
      if(!data.bio){
        showError('Please enter the bio');
        return;
      }
      
      if (!data.phone || !data.countryCode) {
        showError('Please enter the phone number');
        return;
      }

      const { error, message } = validatePhoneNumber(data?.phone, data?.countryCode)
      if (error) {
        showError(message ?? 'Invalid phone number');
        return;
      }


      try {
        setLoading(true);
        if (!id) {
          let form_data = new FormData();
          for (var key in data) {
            let value = isObject(data[key]) || isArray(data[key]) ? JSON.stringify(data[key]) : data[key]
            form_data.append(key, value);
          }
          form_data.append('disable', !!isDisabled);
          const res = await ConsultantService.save(form_data);
          await onFileUpload(res?.data?._id);
          showAlert({
            title: "New Consultant has been Created Successfully",
            description:
              "The details have been sent to the respective consultant's Email Address",
          });
          navigate(`/consultant`);
        }
        else {
          let response = await doPATCH('/v1/api/consultant/profile/update', { ...data, disable: isDisabled })
          if (response?.status == 200) {
            await onFileUpload(data?._id);
            showAlert({
              title: "Consultant has been Updated Successfully",
              description:
                "The details have been sent to the respective consultant's Email Address",
            });
            navigate(`/consultant`);
          }
          else {
            showError(response?.data?.message);
          }
        }
        setLoading(false);
      } catch (error) {
        showError(error);
        return;
      }
      finally {
        setLoading(false);
      }


    
      let response;
      // if (id) {
      //   let form_data = new FormData();
      //   form_data.append('disable', isDisabled);
      //   let isApproved = data?.isApproved != null ? data?.isApproved : null;
      //   form_data.append('isApproved', isApproved);
      //   for (var key in data) {
      //     let value = isObject(data[key]) || isArray(data[key]) ? JSON.stringify(data[key]) : data[key]
      //     form_data.append(key, value);
      //   }
      //   if (profileImage.file) {
      //     form_data.append('profileImage', profileImage.file)
      //   }
      //   response = await ConsultantService.edit(form_data, id);
      // } else {
      //   // if (data.password !== data?.confirmPassword) {
      //   //   showError("Password doesn't match");`
      //   //   return;
      //   // }
      //   let form_data = new FormData();
      //   for (var key in data) {
      //     let value = isObject(data[key]) || isArray(data[key]) ? JSON.stringify(data[key]) : data[key]
      //     form_data.append(key, value);
      //   }
      //   if (profileImage.file) {
      //     form_data.append('profileImage', profileImage.file)
      //   }
      //   response = await ConsultantService.save(form_data);
      // }
      // console.log(response);
      // getDetails();
      // showAlert({
      //   title: id
      //     ? " Consultant has been Updated Successfully"
      //     : "New Consultant has been Created Successfully",
      //   description:
      //     "The details have been sent to the respective consultant's Email Address",
      // });
      // navigate(`/consultant`);
      // showMessage("info", "Consultant saved successfully");
    } catch (err) {
      showError(err?.message);
    } finally {
      setLoading(false)
    }
  };


  const deleteConsultant = async () => {
    if (loading) return;
    try {
      if (await showConfirm({
        title: "Delete Consultant?",
        description: "Are you sure you want to delete this Consultant?",
      })) {
        setLoading(true);
        const res = await ConsultantService.delete(id);
        if (res) {
          showAlert({
            title: "Consultant deleted successfully",
            description: "",
          });
          navigate(`/consultant`);
        }
      }

    } catch (error) {
      showError(error?.message);
    } finally {
      setLoading(false);
    }
  }

  const ChangePassword = async () => {
    try {
      if (password.password !== password?.confirmPassword) {
        showError("Password doesn't match");
        return;
      }
      if (id) {
        setPassword({ ...password, id: id });
        const response = await ConsultantService.updatePassword(password);
      }
      getDetails();
      showAlert({
        title: " Password has been Updated Successfully",
        description:
          "The details have been sent to the respective consultant's Email Address",
      });
      // showMessage("info", "Consultant saved successfully");
    } catch (err) {
      showError(err?.message);
    }
  };
  // const Tags = [
  //   { label: "All", value: "All" },
  //   { label: "Love", value: "Love" },
  //   { label: "Career", value: "Career" },
  //   { label: "Marriage", value: "Marriage" },
  //   { label: "Health", value: "Health" },
  //   { label: "Education", value: "Education" },
  //   { label: "Day to Day life", value: "Day to Day life" },
  // ];

  const onFileUpload = async (id = "") => {
    const selectedFile = fileDetails;
    if(!selectedFile) return;
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.onload = () => { };
      fileReader.readAsDataURL(selectedFile);
    }
  
    // If no file is selected, return early
    if (!selectedFile) {
      return;
    }
  
    try {
      // Prepare form data for file upload
      const formData = new FormData();
      formData.append('_id', id); // Append other necessary data
      formData.append('profileImage', selectedFile); // Append the selected file
  
      // Send the request with FormData
      const response = await doPOST('/v1/api/consultant/profile/pic-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Specify form-data content type
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setIsDisabled(true);
  }

  return (
    <React.Fragment>
      <Card
        className="mt-10 mb-10"
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >

        <CardBody
          className="py-3 px-5"
        >
          <div className="d-flex justify-content-end mt-2">
            <ToggleButton
              placeholder={"Disable"}
              checked={isDisabled}
              value={isDisabled}
              required={true}
              label={"Disable"}
              onClick={() => {
                if (isDisabled)
                  setIsDisabled(false);
                else
                  openModal();
              }}
              type="text"
              className="col-sm-2 col-6 my-3"
            />
            {id &&
              <DropDown
                drp_primary={drpStatus}
                setDrp_primary={setDrpStatus}
                label={status ? (status.value === 1 ? "Approved" : status.value === 0 ? "Dissapproved" : "Status") : "Status"}
                action={StatusOptions}
                className={`btn mt-2 `}
                style={{ fontSize: "15px", backgroundColor: `${status.value === 1 ? "green" : status.value === 0 ? "red" : "white"}`, color: `${status.value === 1 ? "white" : status.value === 0 ? "white" : "black"}`, }}
                borderRadius={"25px"}
                filterName={"Status"}
              />
            }

            {/* // <Button
              //   style={{
              //     background: "#FFCA59",
              //     marginRight: "20px",
              //     border: "none",
              //     color: "black",
              //     height: "40px", width: "80px"
              //   }}
              //   onClick={(event) => {
              //     setIsApproved(!isApproved)
              //     onApprove(event, data?._id, 1);
              //   }}
              // >
              //   Approve
              // </Button> */}


            {/* <strong
              className="px-4 mt-2"
              style={{ fontSize: "15px", color: `${status.value === 1 ? "green" : status.value === 2 ? "red" : "transparent"}` }}
            >
              {status ? (status.value === 1 ? "Approved" : status.value === 2 ? "Dissapproved" : "Status") : "Status"}
            </strong> */}

            {/* {!isApproved ? (<>
              <Button
                style={{ background: "#FF8159", border: "none", color: "black", height: "40px", width: "80px" }}
                disabled={isApproved ? true : false}
                onClick={(event) => {
                  onApprove(event, data?._id, 0);
                }}
              >
                Reject
              </Button>
            </>) : (<></>)} */}

          </div>
          <ProfileSelect
            setFileDetails={setFileDetails}
            fileDetails={fileDetails}
            source={data?.profileImage}
          />
          <Row className="mt-4">
            <Col className="col-sm-8">
              <Label>Name</Label>
              <Row>
                <Col className="col-sm-2">
                  <SelectField
                    data={salutation}
                    placeholder="Salutation"
                    value={data?.salutation ? data?.salutation : 0}
                    onChange={(v) => setData({ ...data, salutation: v.value })}
                  />
                </Col>
                <InputField
                  className="col-sm-4"
                  required={true}
                  placeholder="Enter First Name"
                  value={data?.fname}
                  onChange={(v) => setData({ ...data, fname: v })}
                />
                <InputField
                  className="col-sm-6"
                  required={true}
                  placeholder="Enter Last Name"
                  value={data?.lname}
                  onChange={(v) => setData({ ...data, lname: v })}
                />
              </Row>
            </Col>
            <Col className="col-sm-4">
              <Label>Display Name</Label><font color="#EC734B">*</font>
              <Row>
                <InputField
                  className="col-sm-12"
                  required={true}
                  placeholder="Display Name"
                  value={data?.displayName}
                  onChange={(v) => setData({ ...data, displayName: v })}
                />
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-4">
              <Label>Email</Label> <font color="#EC734B">*</font>
              <InputField
                required={true}
                placeholder="Enter Email Address"
                value={data?.email}
                onChange={(v) => setData({ ...data, email: v })}
              />
            </Col>
            <Col className="col-sm-4">
              <Label>Date of Birth<font color="#EC734B">*</font></Label>
              <DateSelect
                placeholder="Select Date"
                value={data?.dob}
                onChange={(v) => setData({ ...data, dob: v })}
              />
            </Col>
             {id ? <Col className="col-sm-4">
              <Label>Rank</Label>
              <InputField
                placeholder="Rank"
                value={data?.rank}
                // onChange={(rank) => setData({ ...data, extn: { ...data?.extn, rank } })}
                onChange={(rank) => setData({ ...data, rank})}
              />
            </Col> : null}
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-4">
              <Label>Phone No.</Label> <font color="#EC734B">*</font>
              <PhoneNum
                value={{ countryCode: data?.countryCode ? data?.countryCode : 91, phone: data?.phone }}
                onChange={(v) => {
                  setData({
                    ...data,
                    countryCode: v?.countryCode,
                    phone: v?.phone,
                  });
                }}
                required={true}
              />
            </Col>
            <Col className="col-sm-4">
              <Label>Country</Label><font color="#EC734B">*</font>
              <CountrySelect
                name={true}
                placeholder="Enter country"
                value={data?.country}
                onChange={(v) => setData({ ...data, country: v?.name })}
              />
            </Col>
            <Col className="col-sm-4">
              <Label>City</Label>
              <InputField
                placeholder="Enter City"
                value={data?.city}
                onChange={(v) => setData({ ...data, city: v })}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-4">
              <Label>Education Qualification<font color="#EC734B">*</font></Label>
              <SelectField
                data={isNaN(Number(data?.educationQualification)) ? qualification : qualification1}
                placeholder="Education Qualification"
                value={
                  (isNaN(Number(data?.educationQualification))
                    ? qualification
                    : qualification1
                  ).find(item => item?.value === data?.educationQualification)?.value
                }
                onChange={(v) => setData({ ...data, educationQualification: v.value })}
              />


            </Col>
            <Col className="col-sm-4">
              <Label>Primary Skill</Label>

              {data?.coreSkill ? <SelectField
                data={allSkills}
                placeholder="Enter Primary SKill"
                value={data?.coreSkill}
                // value={skills?.filter((c) => data?.primarySkill===c?.label)}
                onChange={(v) => {
                  if (data?.coreSkill) {
                    setData({ ...data, coreSkill: v?.value })
                  }


                }}
              /> : <SelectField
                data={skills}
                placeholder="Enter Primary SKill"
                value={data?.primarySkill}
                // value={skills?.filter((c) => data?.primarySkill===c?.label)}
                onChange={(v) => setData({ ...data, primarySkill: v?.value })}
              />}



            </Col>
            <Col className="col-sm-4">
              <Label>Experience</Label><font color="#EC734B">*</font>
              <InputField
                required={true}
                placeholder="Enter Experience"
                value={data?.experience}
                onChange={(v) => setData({ ...data, experience: v })}
              />
            </Col>

          </Row>
          <Row className="mt-3">
            <Label>Tags</Label>
            {data?.expertise && data?.expertise?.length!=0  ? <SelectFieldMultiple
          data={allSkills}
          placeholder="Skills"
          value={allSkills?.filter((c) => data?.expertise?.includes(c?.value))}
          multi={true}
          onChange={(selectedOptions) => {
            const selectedValues = selectedOptions.map((option) => option?.value);
            setData({
              ...data,
              expertise: selectedValues,
            });
          }}
        /> :

        <SelectFieldMultiple
          data={tags}
          placeholder="Tags"
          value={tags?.filter((c) => data?.tag?.includes(c?.label))}
          multi={true}
          onChange={(v) => {
            setData({
              ...data,
              tag: v.map((c) => c?.label),
            });
          }}
        />
          }


          </Row>
          <Row>
            <Label className="mt-3">Languages<font color="#EC734B">*</font></Label>
            <SelectFieldMultiple
              data={languages}
              placeholder="Languages"
              value={languages?.filter((c) => data?.languages?.includes(c?.code))}
              multi={true}
              onChange={(v) =>
                setData({
                  ...data,
                  languages: v.map((c) => c?.code),
                })
              }
            />
          </Row>
          <Row>
            <Label className="mt-3">Skills</Label>
            { data?.coreSkills && data?.coreSkills?.length!=0 ?
              <SelectFieldMultiple
                data={allSkills}
                placeholder="Skills"
                value={allSkills?.filter((c) => data?.coreSkills?.includes(c?.value))}
                multi={true}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map((option) => option?.value);
                  setData({
                    ...data,
                    coreSkills: selectedValues, // Directly store the array
                  });
                }}
              /> :

         <>
              <SelectFieldMultiple
                data={skills}
                placeholder="Skills"
                value={skills?.filter((c) => data?.skills?.includes(c?.label))}
                multi={true}
                onChange={(v) => {
                  setData({
                    ...data,
                    skills: v.map((c) => c?.label),
                  });
                }}
              /></>

            }


          </Row>
          {data?.attachments ? <Label className="mt-3">Documents</Label> : ""}
          <Row>
            {data?.attachments?.map((ele, i) => (
              <>
                <DocumentDownload
                  documentUrl={`${ele?.doc}`}
                  documentName={ele?.name}
                />
              </>
            ))}
          </Row>
          <Label className="mt-4">Bio <font color="#EC734B">*</font></Label>
          <Col className="col-sm-12">
            <textarea
              value={data?.bio}
              onChange={(v) => {
                setData({ ...data, bio: v.target.value });
              }}
              rows={6}
              cols={160}
              className="mb-2 p-2"
              style={{ border: "1px solid #ced4da", outline: "none" }}
            />
          </Col>
          <Table>
            <Thead>
              <Tr>
                <Th
                  className="col-sm-3"
                  style={{ border: "1px solid #ced4da" }}
                >
                  <div className="d-flex row">
                    <div className="col-6">Enable</div>
                    <div className="col-6">User Status</div>
                  </div>
                </Th>
                <Th
                  className="col-sm-3"
                  style={{ border: "1px solid #ced4da" }}
                >
                  {" "}
                  Type
                </Th>
                <Th
                  className="col-sm-3"
                  style={{ border: "1px solid #ced4da" }}
                >
                  User Rate(AED)
                </Th>
                <Th
                  className="col-sm-3"
                  style={{ border: "1px solid #ced4da" }}
                >
                  Consultant Rate(AED)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <div className="d-flex row">
                    <div className="col-6">
                      <Checkbox
                        checked={data?.chatActive}
                        onClick={(v) =>
                          setData({
                            ...data,
                            chatActive: !data?.chatActive,
                          })
                        }
                      />
                    </div>

                    <div className="col-6">
                      <Checkbox
                        checked={data?.chat}
                        onClick={(v) =>
                          setData({
                            ...data,
                            chat: !data?.chat,
                          })
                        }
                      />
                    </div>
                  </div>

                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Chat</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRate}
                      onChange={(v) => setData({ ...data, chatRate: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRateforConsultant}
                      onChange={(v) =>
                        setData({ ...data, chatRateforConsultant: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <div className="d-flex row">
                    <div className="col-6">
                      <Checkbox
                        checked={data?.callActive}
                        onClick={(v) =>
                          setData({
                            ...data,
                            callActive: !data?.callActive,
                          })
                        }
                      />
                    </div>

                    <div className="col-6">
                      <Checkbox
                        checked={data?.call}
                        onClick={(v) =>
                          setData({
                            ...data,
                            call: !data?.call,
                          })
                        }
                      />
                    </div>
                  </div>
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Audio Call</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRate}
                      onChange={(v) => setData({ ...data, callRate: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRateforConsultant}
                      onChange={(v) =>
                        setData({ ...data, callRateforConsultant: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <div className="d-flex row">
                    <div className="col-6">
                      <Checkbox
                        checked={data?.videoCallActive}
                        onClick={(v) =>
                          setData({
                            ...data,
                            videoCallActive: !data?.videoCallActive,
                          })
                        }
                      />
                    </div>

                    <div className="col-6">
                      <Checkbox
                        checked={data?.videoCall}
                        onClick={(v) =>
                          setData({
                            ...data,
                            videoCall: !data?.videoCall,
                          })
                        }
                      />
                    </div>
                  </div>

                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Video Call</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRate}
                      onChange={(v) => setData({ ...data, videoCallRate: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRateforConsultant}
                      onChange={(v) =>
                        setData({ ...data, videoCallRateforConsultant: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.chat30Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        chat30Min: !data?.chat30Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Chat  30 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRate30Min}
                      onChange={(v) => setData({ ...data, chatRate30Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRateforConsultant30Min}
                      onChange={(v) =>
                        setData({ ...data, chatRateforConsultant30Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.call30Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        call30Min: !data?.call30Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Audio Call 30 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRate30Min}
                      onChange={(v) => setData({ ...data, callRate30Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRateforConsultant30Min}
                      onChange={(v) =>
                        setData({ ...data, callRateforConsultant30Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.videoCall30Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        videoCall30Min: !data?.videoCall30Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Video Call 30 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRate30Min}
                      onChange={(v) => setData({ ...data, videoCallRate30Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall30Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRateforConsultant30Min}
                      onChange={(v) =>
                        setData({ ...data, videoCallRateforConsultant30Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.chat60Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        chat60Min: !data?.chat60Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Chat 60 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRate60Min}
                      onChange={(v) => setData({ ...data, chatRate60Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.chatRateforConsultant60Min}
                      onChange={(v) =>
                        setData({ ...data, chatRateforConsultant60Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>


              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.call60Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        call60Min: !data?.call60Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Audio Call 60 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRate60Min}
                      onChange={(v) => setData({ ...data, callRate60Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.call60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.callRateforConsultant60Min}
                      onChange={(v) =>
                        setData({ ...data, callRateforConsultant60Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>
              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                  <Checkbox
                    checked={data?.videoCall60Min}
                    onClick={(v) =>
                      setData({
                        ...data,
                        videoCall60Min: !data?.videoCall60Min,
                      })
                    }
                  />
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Video Call 60 Minutes</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRate60Min}
                      onChange={(v) => setData({ ...data, videoCallRate60Min: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.videoCall60Min && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.videoCallRateforConsultant60Min}
                      onChange={(v) =>
                        setData({ ...data, videoCallRateforConsultant60Min: v })
                      }
                    />
                  )}
                </Th>
              </Tr>

              <Tr>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {" "}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}> Offline Query Rate</Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.offlineQueryRate}
                      onChange={(v) => setData({ ...data, offlineQueryRate: v })}
                    />
                  )}
                </Th>
                <Th style={{ border: "1px solid #ced4da" }}>
                  {data?.chat && (
                    <InputField
                      className="col-sm-8"
                      placeholder="0.00"
                      value={data?.offlineQueryRateforConsultant}
                      onChange={(v) =>
                        setData({ ...data, offlineQueryRateforConsultant: v })
                      }
                    />
                  )}
                </Th>
              </Tr>

            </Tbody>
          </Table>
          <Row className="mt-3"></Row>
          <Row className="mt-3">
            {data?.bankDetails != [] ? <Label>Bank Details</Label> : ""}
            {data?.bankDetails?.map((ele, idx) => (
              <BankDetailComponent setData={setData} ele={ele} idx={idx} setBankDetailsError={setBankDetailsError} />
            ))}

            <Row className="mt-5 mb-5 d-flex justify-content-end">
              {/* <SaveButton
                style={{ right: "19%" }}
                onClick={addBankDetailRow}
                label='Add Bank Detail'
                disabled={data ? false : true}
              /> */}
              <div
                className="d-flex justify-content-end"

                style={{ color: "blue", cursor: 'pointer' }}
                onClick={addBankDetailRow}
                disabled={data ? false : true}
              >
                <Row style={{ cursor: 'pointer' }}>
                  <b>+ Add Bank Detail</b>
                </Row>

              </div>


            </Row>
          </Row>
          <Row className="mt-3 d-flex justify-content-end">
            {id ? <SaveButton
              loading={loading}
              onClick={deleteConsultant}
              label={"Delete"}
              disabled={!loading ? false : true}
              className="col-2"
            /> : null}
            <SaveButton
              loading={loading}
              style={{ right: "0%" }}
              onClick={submitForm}
              label={id ? "Update" : "Add"}
              disabled={data ? false : true}
              className="col-2"
            />
          </Row>
          <Row className="mt-5"></Row>
        </CardBody>

      </Card>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            margin: 'auto',
            maxWidth: "500px",
            borderRadius: "16px",
            height: 270
          },
        }}
      >
        <div style={{ cursor: "pointer" }}>
          <CrossButton onClick={() => { setModalIsOpen(false) }} />
        </div>
        <h4>Disable Reason</h4>
        {/* <InputField
          className="pt-3"
          placeholder="Enter Disable Reason"
          value={data?.disableReason}
          onChange={(v) => setData({ ...data, disableReason: v })}
        /> */}
        <textarea
          value={data?.disableReason}
          onChange={(v) => {
            setData({ ...data, disableReason: v.target.value });
          }}
          rows={5}
          className="mt-3 w-100"
          style={{ border: "1px solid #ced4da", outline: "none" }}
        />
        <SaveButton
          style={{ right: "0%" }}
          onClick={closeModal}
          label={"Save"}
        />
      </Modal>
    </React.Fragment>
  );
};

export default observer(EditConsultant);
